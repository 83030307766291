import React from 'react';


function Footer () {
    return (
        <div>
            <hr/>
            <div className="nav nav-fill justify-content-center">
                <a className="nav-item" href='/'>Home</a>
                <a className="nav-item" href='/about'>About</a>
                <a className="nav-item" href='/faq'>FAQ</a>
                <a className="nav-item" href='/analytics'>Sermon Analyzer (Beta)</a>
            </div>
            <div className="nav nav-fill justify-content-center">
                <div className="nav-item">Translations powered by the <a href="https://greekbible.org/">Berean Bible</a>.</div>
            </div>
        </div>
    );
}

export default Footer