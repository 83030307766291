export const bookMap = {
    0:'Genesis',
    1:'Exodus',
    2:'Leviticus',
    3:'Numbers',
    4:'Deuteronomy',
    5:'Joshua',
    6:'Judges',
    7:'Ruth',
    8:'1 Samuel',
    9:'2 Samuel',
    10: '1 Kings',
    11: '2 Kings',
    12: '1 Chronicles',
    13: '2 Chronicles',
    14: 'Ezra',
    15: 'Nehemiah',
    16: 'Esther',
    17: 'Job',
    18: 'Psalm',
    19: 'Proverbs',
    20: 'Ecclesiastes',
    21: 'Songs',
    22: 'Isaiah',
    23: 'Jeremiah',
    24: 'Lamentations',
    25: 'Ezekiel',
    26: 'Daniel',
    27: 'Hosea',
    28: 'Joel',
    29: 'Amos',
    30: 'Obadiah',
    31: 'Jonah',
    32: 'Micah',
    33: 'Nahum',
    34: 'Habakkuk',
    35: 'Zephaniah',
    36: 'Haggai',
    37: 'Zechariah',
    38: 'Malachi',
    39: 'Matthew',
    40: 'Mark',
    41: 'Luke',
    42: 'John',
    43: 'Acts',
    44: 'Romans',
    45: '1 Corinthians',
    46: '2 Corinthians',
    47: 'Galatians',
    48: 'Ephesians',
    49: 'Philippians',
    50: 'Colossians',
    51: '1 Thessalonians',
    52: '2 Thessalonians',
    53: '1 Timothy',
    54: '2 Timothy',
    55: 'Titus',
    56: 'Philemon',
    57: 'Hebrews',
    58: 'James',
    59: '1 Peter',
    60: '2 Peter',
    61: '1 John',
    62: '2 John',
    63: '3 John',
    64: 'Jude',
    65: 'Revelation',
};

export const bookStyles = {
    'Gen': '#ffcccc',
    'Exo': '#ffcccc',
    'Lev': '#ffcccc',
    'Num': '#ffcccc',
    'Deu': '#ffcccc',
    'Jos': '#ffbf80',
    'Jdg': '#ffbf80',
    'Rut': '#ffbf80',
    '1Sa': '#ffbf80',
    '2Sa': '#ffbf80',
    '1Ki': '#ffbf80',
    '2Ki': '#ffbf80',
    '1Ch': '#ffbf80',
    '2Ch': '#ffbf80',
    'Ezr': '#ffbf80',
    'Neh': '#ffbf80',
    'Est': '#ffbf80',
    'Job': '#ffb3ff',
    'Psa': '#ffb3ff',
    'Pro': '#ffb3ff',
    'Ecc': '#ffb3ff',
    'Son': '#ffb3ff',
    'Isa': '#ff99bb',
    'Jer': '#ff99bb',
    'Lam': '#ff99bb',
    'Eze': '#ff99bb',
    'Dan': '#ff99bb',
    'Hos': '#ff99bb',
    'Joe': '#ff99bb',
    'Amo': '#ff99bb',
    'Oba': '#ff99bb',
    'Jon': '#ff99bb',
    'Mic': '#ff99bb',
    'Nah': '#ff99bb',
    'Hab': '#ff99bb',
    'Zep': '#ff99bb',
    'Hag': '#ff99bb',
    'Zec': '#ff99bb',
    'Mal': '#ff99bb',
    'Mat': '#00b300',
    'Mar': '#00b300',
    'Luk': '#00b300',
    'Joh': '#00b300',
    'Act': '#00b300',
    'Rom': '#c299ff',
    '1Co': '#c299ff',
    '2Co': '#c299ff',
    'Gal': '#c299ff',
    'Eph': '#c299ff',
    'Phi': '#c299ff',
    'Col': '#c299ff',
    '1Th': '#c299ff',
    '2Th': '#c299ff',
    '1Ti': '#80ffff',
    '2Ti': '#80ffff',
    'Tit': '#80ffff',
    'Phm': '#80ffff',
    'Heb': '#e6b3b3',
    'Jam': '#e6b3b3',
    '1Pe': '#e6b3b3',
    '2Pe': '#e6b3b3',
    '1Jo': '#e6b3b3',
    '2Jo': '#e6b3b3',
    '3Jo': '#e6b3b3',
    'Jud': '#e6b3b3',
    'Rev': '#ff99bb',
};

export const oldTestamentBooks = {
    0:'Genesis',
    1:'Exodus',
    2:'Leviticus',
    3:'Numbers',
    4:'Deuteronomy',
    5:'Joshua',
    6:'Judges',
    7:'Ruth',
    8:'1 Samuel',
    9:'2 Samuel',
    10: '1 Kings',
    11: '2 Kings',
    12: '1 Chronicles',
    13: '2 Chronicles',
    14: 'Ezra',
    15: 'Nehemiah',
    16: 'Esther',
    17: 'Job',
    18: 'Psalm',
    19: 'Proverbs',
    20: 'Ecclesiastes',
    21: 'Songs',
    22: 'Isaiah',
    23: 'Jeremiah',
    24: 'Lamentations',
    25: 'Ezekiel',
    26: 'Daniel',
    27: 'Hosea',
    28: 'Joel',
    29: 'Amos',
    30: 'Obadiah',
    31: 'Jonah',
    32: 'Micah',
    33: 'Nahum',
    34: 'Habakkuk',
    35: 'Zephaniah',
    36: 'Haggai',
    37: 'Zechariah',
    38: 'Malachi',
};

export const newTestamentBooks = {
    39: 'Matthew',
    40: 'Mark',
    41: 'Luke',
    42: 'John',
    43: 'Acts',
    44: 'Romans',
    45: '1 Corinthians',
    46: '2 Corinthians',
    47: 'Galatians',
    48: 'Ephesians',
    49: 'Philippians',
    50: 'Colossians',
    51: '1 Thessalonians',
    52: '2 Thessalonians',
    53: '1 Timothy',
    54: '2 Timothy',
    55: 'Titus',
    56: 'Philemon',
    57: 'Hebrews',
    58: 'James',
    59: '1 Peter',
    60: '2 Peter',
    61: '1 John',
    62: '2 John',
    63: '3 John',
    64: 'Jude',
    65: 'Revelation',
};

export const lastChapters = {
    0: 50,
    1: 40,
    2: 27,
    3: 36,
    4: 34,
    5: 24,
    6: 21,
    7: 4,
    8: 31,
    9: 24,
    10: 22,
    11: 25,
    12: 29,
    13: 36,
    14: 10,
    15: 13,
    16: 10,
    17: 42,
    18: 150,
    19: 31,
    20: 12,
    21: 8,
    22: 66,
    23: 52,
    24: 5,
    25: 48,
    26: 12,
    27: 14,
    28: 3,
    29: 9,
    30: 1,
    31: 4,
    32: 7,
    33: 3,
    34: 3,
    35: 3,
    36: 2,
    37: 14,
    38: 4,
    39: 28,
    40: 16,
    41: 24,
    42: 21,
    43: 28,
    44: 16,
    45: 16,
    46: 13,
    47: 6,
    48: 6,
    49: 4,
    50: 4,
    51: 5,
    52: 3,
    53: 6,
    54: 4,
    55: 3,
    56: 1,
    57: 13,
    58: 5,
    59: 5,
    60: 3,
    61: 5,
    62: 1,
    63: 1,
    64: 1,
    65: 22,
}
