import _ from 'lodash';
import React from 'react';
import store from "../../js/store/index";
import { connect } from "react-redux";
import {bookMap} from '../common/constants'
import {Commentary} from '../commentary/commentary'
import {addWord, removeWord} from '../../js/actions'
import {SimpleModal} from '../common/modals'


class ConnectedWord extends React.Component {
    constructor () {
        super();
        this.state = {isSelected: false};
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick () {
        if (this.state.isSelected) {
            store.dispatch(removeWord(this.props.word.sort));
        } else {
            store.dispatch(addWord(this.props.word.sort));
        }
        this.setState(state => ({
            isSelected: !this.state.isSelected,
        }));
    }
    render(){
        if (this.props.multiSelect) {
            if (this.state.isSelected) {
                return <a className="quick-nav-item" onClick={this.handleClick} style={{color: 'red'}} href='javascript:;'>
                    {this.props.word.ngram} ({this.props.word.strongs_number})
                </a>;
            } else {
                return <a className="quick-nav-item" onClick={this.handleClick} href='javascript:;'>
                    {this.props.word.ngram} ({this.props.word.strongs_number})
                </a>;
            }
        }
        if (this.props.interlinear) {
            return <div className='verse'> <a href={'/words/' + this.props.word.sort}>
            <strong>{this.props.word.transliteration}</strong></a> &rarr; {this.props.word.parsing} &rarr; {this.props.word.ngram} </div>;
        }
        var cls = 'verse';
        if (this.props.word.is_imperative) {
            cls = 'imperative';
        } else if (this.props.word.is_subjunctive) {
            cls = 'subjunctive';
        } else if (this.props.word.is_connector) {
            cls = 'connector';
        }
        if (this.props.strongs && this.props.strongs.indexOf(this.props.word.strongs_number) > -1) {
            var word = <a className={cls} href={'/words/' + this.props.word.sort}>
                           <strong>{this.props.word.ngram} </strong>
                       </a>;
        } else {
            var word = <a className={cls} href={'/words/' + this.props.word.sort}>{this.props.word.ngram} </a>;
        }
        if (this.props.showStrongs) {
            word = <span>
                {word}
                <sup className="strongs">{ this.props.word.strongs_number } </sup>
          </span>
        }
        return (
            <span className={cls}>
                {word}
            </span>
        );
    }
}


const Word = connect(state => {
  return { showStrongs: state.showStrongs, multiSelect: state.multiSelect, interlinear: state.interlinear };
})(ConnectedWord);


function Verse(props) {
    if (!props.usage) {
        var verseNo = <span><sup className="verse-number">{props.verse.verse}</sup> </span>;
    } else {
        var navLink = '/?book=' + _.invert(bookMap)[props.verse.book] + '&chapter=' + props.verse.chapter;
        var verseNo = (<span><a href={navLink} className="verse-number">
            <strong>{props.verse.book} {props.verse.chapter}:{props.verse.verse}</strong>
        </a>:&nbsp;&nbsp;</span>);
    }
    var usage = props.usage;
    var strongs = props.strongs;
    var showStrongs = props.showStrongs;
    var verse = _.map(
        props.verse.word_list,
        function(word, idx) {
            return <Word key={idx} word={word} usage={usage} strongs={strongs} showStrongs={showStrongs}/>;
        }
    )
    if (props.verse.cross_references && props.verse.cross_references.length > 0) {
        var crossRefs = _.map(
            _.sortBy(props.verse.cross_references, 'id'),
            function(item) {return item.text}
        ).join('; ');
        var crossReferenceModal = <SimpleModal content={crossRefs} contentLabel={'Cross-Reference'}
                                   iconName={'fas fa-link'}/>;
    } else {
        var crossReferenceModal = <></>;
    }

    if (props.verse.commentaries && props.verse.commentaries.length > 0) {
        return (
            <div className={props.usage ? 'usage' : ''}>{verseNo}
                {verse}
                {crossReferenceModal}
                &nbsp;<Commentary commentaries={props.verse.commentaries}/>
            </div>
        );
    } else {
        return (
            <div className={props.usage ? 'usage' : ''}>{verseNo}
                {verse}
                {crossReferenceModal}
            </div>
        );
    }
}


export default Verse;