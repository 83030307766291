import _ from 'lodash';
import React from 'react';


class Commentary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showCommentaries: false};
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.setState(state => ({
            showCommentaries: !this.state.showCommentaries,
        }));

    };
    render() {
        if (this.state.showCommentaries) {
            return (
                <span>
                    <i className="fas fa-book-open" onClick={this.handleClick}></i>
                    {
                        _.map(
                            this.props.commentaries,
                            function(commentary, key) {
                                return <div className="commentary"><h5>{commentary.commentator}</h5>
                                    <span dangerouslySetInnerHTML={{__html: commentary.text}}></span>
                                </div>;
                            }
                        )
                    }
                </span>
            )
        } else {
            return <i className="fas fa-book-open" onClick={this.handleClick}></i>
        }
  }
}


export {Commentary};