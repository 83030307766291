import _ from 'lodash';


function havePropsChanged(oldProps, newProps, keys){
    var change = false;
    if (!keys) {
        keys = Object.keys(newProps);
    }
    _.each(keys, function(key) {
        if (oldProps[key] !== newProps[key]) {
          change = true;
        }
    });
    return change;
}

export default havePropsChanged;