import React from "react";

import {Chapter, Introduction} from "../chapter/chapter";
import Footer from "./footer";
import QuickNav from "./quickNav";

import {hideStrongs, showStrongs} from '../../js/actions'
import store from "../../js/store/index";

class Container extends React.Component {
    constructor (props) {
        super(props);
    }
    render () {
        return <div className="container">
            <QuickNav/>
            {this.props.component}
            <Footer/>
        </div>;
    }
}

class ChapterContainer extends React.Component {
    constructor (props) {
        super(props);
    }
    render () {
        if (this.props.chapter) {
            return <div className="container">
                <QuickNav chapterView={true}/>
                <Chapter book={this.props.book} chapter={this.props.chapter}/>
                <Footer/>
            </div>;
        } else {
            return <div className="container">
                <QuickNav chapterView={true}/>
                <Introduction book={this.props.book}/>
                <Footer/>
            </div>;
        }
    }
}

export {Container, ChapterContainer}