import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import Verse from '../common/verse';
import havePropsChanged from '../common/services';


function AbbottSmithEtymology(props) {
    var links = _.map(props.etym.description, function(wordTuple, idx) {
        if (!wordTuple[1]) {
            return <span key={idx}>{wordTuple[0] + ' '}</span>;
        }
        return <a key={idx} href={'/definition/' + wordTuple[1]}>{wordTuple[0] + ' '}</a>
    });
    return <div><strong>{props.etym.etymology_type}</strong>: {links}</div>;
}


function AbbottSmithEntry(props) {
    var etymologies = _.map(props.entry.abbott_smith_etymologies, function (etym, idx) {
        return <div key={idx}>
            <div><strong>Etymology</strong></div>
            <AbbottSmithEtymology etym={etym}/>
        </div>
    });

    var subSenseList = _.map(props.entry.subsenses, function(subSense, idx) {
        return <li key={idx}>{subSense}</li>
    });
    if (subSenseList) {
        var subSenses = <ol>{subSenseList}</ol>
    } else {
        var subSenses = <div/>
    }

    return <div>
        <h3>Abbott-Smith</h3>
        <div><strong>NT Entries</strong>: {props.entry.nt_occurances}</div>
        {etymologies}
        <div><strong>Definition</strong>: {props.entry.sense}</div>
        {subSenses}
    </div>;
}


class Definition extends React.Component {
    constructor(){
        super();
        this.state = {};
    }

    async getData(){
        const strongs = await axios.get('/api/definitions/' + this.props.strongs + '/');
        this.setState(state => ({
          strongsData: strongs.data
        }));
    }
    componentDidMount(){
       this.getData();
    }
    render () {
        if (!this.state.strongsData) {return <div/>}
        var data = this.state.strongsData;
        var strongsDefinition = (<div>
            <hr/>
            <h3>{"Translation"}</h3>
            <div><strong>Lemma</strong>: <span style={{fontWeight: '250'}}>{data.lemma}</span></div>
            <div>
                <strong>{"Strong's"}</strong>:
                <a href={'/definition/' + data.strongs_number}> {data.strongs_number} </a>
            </div>
            <div><strong>{"BDB/Thayer's"}</strong>: {data.definition}</div>
            <hr/>
        </div>)
        var abbottSmithEntries = _.map(data.abbott_smith_entries, function (entry, idx) {
                return <AbbottSmithEntry key={idx} entry={entry}/>;
            });
        var relatedWordData = _.map(data.related_words, function (relatedWord, idx) {
            if (idx + 1 === data.related_words.length){
                return <span key={idx}><a href={'/definition/' + relatedWord.strongs_number}>
                    {relatedWord.lemma + ' (' + relatedWord.strongs_number + ')'}</a>
                </span>
            }
            return <span key={idx}><a href={'/definition/' + relatedWord.strongs_number}>
                {relatedWord.lemma + ' (' + relatedWord.strongs_number + ')'}</a>,
            </span>
        });
        var relatedWords = <div/>
        if (data.related_words.length > 0) {
            relatedWords = <div><strong>Related Words</strong>: {relatedWordData}</div>
        }


        return <div>
            {strongsDefinition}
            {abbottSmithEntries}
            {relatedWords}
            <hr/>
            <ToggleDefinitionUsages strongs={this.props.strongs}/>
        </div>;
    }

}


class Usage extends React.Component {
    constructor(){
        super();
        this.state = {};
    }
    async getData(){
        const word = await axios.get('/api/words/' + this.props.word + '/');
        this.setState(state => ({
          wordData: word.data
        }));
    }
    componentDidMount(){
       this.getData();
    }
    render() {
        if (!this.state.wordData) {return <div/>};
        var data = this.state.wordData;
        var strongsDefinition = (<div>
            <hr/>
            <h3>{"Translation"}</h3>
            <div><strong>Translation</strong>: {data.ngram} </div>
            <div><strong>Parsing</strong>: {data.parsing}</div>
            <div><strong>Transliteration</strong>: {data.transliteration}</div>
            <div><strong>Lemma</strong>: <span style={{fontWeight: '250'}}>{data.strongs_number.lemma}</span></div>
            <div>
                <strong>{"Strong's"}</strong>:
                <a href={'/definition/' + data.strongs_number.strongs_number}> {data.strongs_number.strongs_number} </a>
            </div>
            <div><strong>{"BDB/Thayer's"}</strong>: {data.strongs_number.definition}</div>
            <hr/>
        </div>)
        var abbottSmithEntries = _.map(data.strongs_number.abbott_smith_entries, function (entry, idx) {
                return <AbbottSmithEntry key={idx} entry={entry}/>;
            });
        var relatedWordData = _.map(data.strongs_number.related_words, function (relatedWord, idx) {
            if (idx + 1 === data.strongs_number.related_words.length){
                return <span key={idx}><a href={'/definition/' + relatedWord.strongs_number}>{relatedWord.lemma + ' (' + relatedWord.strongs_number + ')'}</a></span>
            }
            return <span key={idx}><a href={'/definition/' + relatedWord.strongs_number}>{relatedWord.lemma + ' (' + relatedWord.strongs_number + ')'}</a>, </span>
        });
        var relatedWords = <div/>
        if (data.strongs_number.related_words.length > 0) {
            relatedWords = <div><strong>Related Words</strong>: {relatedWordData}</div>
        }


        return <div>
            {strongsDefinition}
            {abbottSmithEntries}
            {relatedWords}
            <hr/>
            <ToggleWordUsages word={this.props.word} book={this.state.wordData.verse.book_id}/>
        </div>;
    }
}

function MultiUsage(props) {
    const words = props.selectedWords.split(',').join(', ');
    return (<div>
        <h3>Phrase Usages</h3>
        <MultiUsageList selectedWords={props.selectedWords}/>
    </div>)
}


class ToggleWordUsages extends React.Component {
    constructor(){
        super();
        this.state = {isClicked: false, isClickedAuthor: false};
        this.showUsagesAll = this.showUsagesAll.bind(this);
        this.showUsagesAuthor = this.showUsagesAuthor.bind(this);
        this.showUsagesBook = this.showUsagesBook.bind(this);
    }
    showUsagesAll(){
        this.setState(state => ({
          isClicked: true,
          isClickedAuthor: false,
          isClickedBook: false,
        }));
    }
    showUsagesAuthor(){
        this.setState(state => ({
          isClicked: false,
          isClickedAuthor: true,
          isClickedBook: false,
        }));
    }
    showUsagesBook(){
        this.setState(state => ({
          isClicked: false,
          isClickedAuthor: false,
          isClickedBook: true,
        }));
    }
    render () {
        if (this.state.isClicked) {
            return <div>
                <a href="javascript:;" className='quick-nav-item' onClick={this.showUsagesAuthor}>
                    Show Usages (Author)
                </a>
                <a href="javascript:;" className='quick-nav-item' onClick={this.showUsagesBook}>
                    Show Usages (Book)
                </a>
                <br/>
                <UsageList word={this.props.word} sameAuthor={false}/>
            </div>;
        } else if (this.state.isClickedAuthor) {
            return <div>
                <a href="javascript:;" className='quick-nav-item' onClick={this.showUsagesAll}>
                    Show Usages (All)
                </a>
                <a href="javascript:;" className='quick-nav-item' onClick={this.showUsagesBook}>
                    Show Usages (Book)
                </a>
                <br/>
                <UsageList word={this.props.word} sameAuthor={true}/>
            </div>;
        } else if (this.state.isClickedBook) {
            return <div>
                <a href="javascript:;" className='quick-nav-item' onClick={this.showUsagesAll}>
                    Show Usages (All)
                </a>
                <a href="javascript:;" className='quick-nav-item' onClick={this.showUsagesAuthor}>
                    Show Usages (Author)
                </a>
                <br/>
                <UsageList word={this.props.word} book={this.props.book}/>
            </div>;
        }
        return <div>
            <a href="javascript:;" className='quick-nav-item' onClick={this.showUsagesAll}>Show Usages (All)</a>
            <a href="javascript:;" className='quick-nav-item' onClick={this.showUsagesAuthor}>Show Usages (Author)</a>
            <a href="javascript:;" className='quick-nav-item' onClick={this.showUsagesBook}>Show Usages (Book)</a>
            <br/>
        </div>;
    }
}


class ToggleDefinitionUsages extends React.Component {
    constructor(){
        super();
        this.state = {isClicked: false, isClickedAuthor: false};
        this.showUsages = this.showUsages.bind(this);
    }
    showUsages(){
        this.setState(state => ({
          isClicked: !this.state.isClicked,
        }));
    }
    render () {
        if (this.state.isClicked) {
            if (this.props.book) {
                return <>
                    <button className='quick-nav-item' onClick={this.showUsages}>
                        Hide Usages
                    </button>
                    <br/>
                    <UsageList strongs={this.props.strongs} book={this.props.book}/>
                </>;
            } else {
                return <>
                    <button className='quick-nav-item' onClick={this.showUsages}>
                        Hide Usages
                    </button>
                    <br/>
                    <UsageList strongs={this.props.strongs} sameAuthor={false}/>
                </>;
            }
        }
        return <>
            <a href="javascript:;" className='quick-nav-item' onClick={this.showUsages}>Show Usages</a>
            <br/>
        </>;
    }
}


class UsageVerses extends React.Component {
    render() {
        var data = this.props.usageData;
        return _.map(data.verses, function(item) {
            return <Verse key={item.id} verse={item} usage={true} strongs={data.strongs}/>;
        })
    }
}


class MultiUsageList extends React.Component {
    constructor(){
        super();
        this.state = {};
    }
    async getData(){
        var url = '/api/search_phrase/?selectedWords=' + this.props.selectedWords;
        const usageData = await axios.get(url);
        this.setState(state => ({
          usageData: usageData.data
        }));
    }
    componentDidMount(){
        this.getData();
    }

    componentDidUpdate(prevProps){
        if (havePropsChanged(prevProps, this.props)) {
            this.getData();
        }
    }

    render() {
        if (!this.state.usageData) {return <div>Loading...</div>};
        return <div><UsageVerses usageData={this.state.usageData}/></div>;
    }
}


class UsageList extends React.Component {
    constructor(){
        super();
        this.state = {};
    }
    async getData(){
        if (this.props.word) {
            var url = '/api/search_phrase/?sameAuthor=' + this.props.sameAuthor + '&selectedWords=' + this.props.word;
        } else if (this.props.strongs) {
            var url = '/api/search_phrase/?strongsNumber=' + this.props.strongs;
        }
        if (this.props.book) {
            url += '&book=' + this.props.book;
        }
        const usageData = await axios.get(url);
        this.setState(state => ({
          usageData: usageData.data
        }));
    }
    componentDidMount(){
        this.getData();
    }

    componentDidUpdate(prevProps){
        if (havePropsChanged(prevProps, this.props)) {
            this.getData();
        }
    }

    render() {
        if (!this.state.usageData) {return <div>Loading...</div>};
        return <div><UsageVerses usageData={this.state.usageData}/></div>;
    }
}


export {Definition, MultiUsage, ToggleDefinitionUsages, Usage};
