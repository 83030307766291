import _ from 'lodash';
import React from 'react';
import Modal from 'react-modal';


Modal.setAppElement('#root')


var customStyles = {
  content : {
    top : '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};


export class SimpleModal extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    if (this.props.width) {
        customStyles['content'] = _.extend(customStyles['content'], {width: this.props.width});
    }
    return (<>
        <i href="javascript:;" onClick={this.openModal} className={this.props.iconName}></i>
        <Modal isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal}
               contentLabel={this.props.contentLabel} style={customStyles}>
           &nbsp;<button className={'pull-right close'} onClick={this.closeModal}>&times;</button>
           {this.props.content}
        </Modal>
    </>)
  }
}
