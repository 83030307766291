import React from 'react';

function About () {
    return (<div>
        <h3>What is Concordy?</h3>
        <p>
            Concordy is a free Bible study tool that provides quick and easy access to original languages. It serves as a
            lightweight and free alternative to more robust (and objectively superior!) Greek and Hebrew language software
            such as Accordance or Logos. It is four-in-one (1) a reverse
            interlinear <a href="https://en.wikipedia.org/wiki/Bible_concordance">concordance</a>,
            (2) <a href="https://en.wikipedia.org/wiki/Lexicon">lexicon</a> with Hebrew-to-Greek and Greek-to-Hebrew
            equivalents, (3) word-by-word grammatical parsings
            (<a href="https://en.wikipedia.org/wiki/Declension">declensions</a> and
            <span> <a href="https://en.wikipedia.org/wiki/Grammatical_conjugation">conjugations</a></span>), and (4) original-language
            search engine allowing users search for combinations of words in the original languages.
        </p>
        <p>
            The goal is to present the user with a readable English text, and yet let them do a full-fledged word or phrase
            study in 1-2 clicks.
        </p>
        <h3>What makes Concordy different?</h3>
        <p>
            The main distinctive of Concordy is that it uses a modern (critical) text. Most free concordances use the KJV,
            which is translated from the <a href="https://en.wikipedia.org/wiki/Textus_Receptus">Textus Receptus</a>. But
            most modern English Bibles are translated from a <a href="https://www.gotquestions.org/critical-text.html">
            critical text</a> (with the KJV and NKJV as notable exceptions). As a result, your (critical text) Bible might
            not match your (Textus Receptus) concordance, confusing your word studies.
        </p>
        <p>
            For example, Ephesians 1:18 in modern Bibles might read  something like "the eyes of
            your <a href="/words/715091"><b>heart</b></a>..." But in most concordances, it reads: "The eyes of
            your <a href="https://www.blueletterbible.org/kjv/eph/1/1/t_conc_1098018"><b>understanding</b></a>..." That's not
            just a difference of translation: it's a difference in the underlying Greek text.
        </p>
        <h3>How not to use this tool</h3>
        <p>
            This tool gives users easy access to the underlying texts, which is a double-edged sword. On the one hand, it
            allows users to quickly lookup words, perform word studies, and understand basic grammar of a sentence. But
            on the other, it can give users a false confidence in their ability to "correct" translations. These sort of
            amateur corrections are particularly dangerous when the translation relies upon grammatical structures that the
            user might not be familiar with.
        </p>
        <p>
            For example, the word rendered "<a href="/words/574049">go</a>" in Matthew 28:17 is not actually in the
            imperative mood, it's an <a href="/words/574049">aorist participle</a>. From this fact, someone might reason
            that "go" would be better translated as "as you go" or "going" (like the English gerund). But in fact, the grammatical
            construction means it's more likely
            an <a href="https://www.billmounce.com/monday-with-mounce/great-commission-and-participles">attendance
            circumstances participle</a>, and therefore the ESV's or NASB's rendering of "go" is probably more correct.
        </p>
        <p>
            <b>Note well</b>: If you can't find another translation (or at least a footnote) that agrees with your
            rendering, it's probably your rendering that's wrong, not 500 years of English Bible translation. If users
            want a better understanding of certain translational choices and grammatical structures, a good place to start
            is the translators' notes for the <a href="https://netbible.org/bible/Matthew+28">NETBible</a>.
        </p>
    </div>);
}

export default About;