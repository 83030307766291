import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import Verse from '../common/verse';
import {bookMap, lastChapters} from '../common/constants'
import {ToggleDefinitionUsages} from '../usage/usage'


function NavButton(props) {
    var style = props.direction === 'Previous' ? {marginRight: '10px'} : {marginLeft: '10px'};
    if (props.book === null || props.book < 0 || props.book > 65) {
        return <a style={style} className="nav-item quick-nav-item" disabled>{props.direction}</a>;
    }
    var navLink = '/?book=' + props.book;
    if (props.chapter) {
        navLink += '&chapter=' + props.chapter
    }
    return <a href={navLink} style={style} className="nav-item quick-nav-item">{props.direction}</a>;
}


function Title(props) {
    return <h3>{bookMap[props.book]} {props.chapter}</h3>;
}


function KeyWord(props) {
    var definition = <td style={{padding: '5px'}}><a href={"/definition/" + props.keyWord.strongs_number}>{props.keyWord.strongs_number}</a></td>;
    var count = <td style={{padding: '5px'}}>{props.keyWord.count}</td>;
    var usage = <td style={{padding: '5px'}}>{props.keyWord.usages}</td>;
    if (props.usageButton) {
        return (
            <>
                <tr className="dFlex">
                    {definition}
                    {count}
                    {usage}
                </tr>
                <tr className="dFlex">
                    <td colSpan='99' style={{width: '100%', padding: '5px'}}>
                        <ToggleDefinitionUsages strongs={props.keyWord.strongs_number} book={props.book}/>
                    </td>
                </tr>
            </>
        );
    } else {
        return (
            <tr>
                {definition}
                {count}
                {usage}
            </tr>
        );
    }
}


class KeyWordList extends React.Component {
    constructor(){
        super();
        this.state = {};
    }
    async getData(){
        var url = '/api/keywords/?book=' + this.props.book;
        if (this.props.chapter) {
            url += '&chapter=' + this.props.chapter;
        }
        const keyWordData = await axios.get(url);
        this.setState({keyWords: keyWordData.data});
    }
    componentDidMount(){
       this.getData();
    }
    render() {
        var usageButton = !Boolean(this.props.chapter);
        var book = this.props.book;
        var keyWords = _.map(
            this.state.keyWords,
            function(keyWord, idx) {return <KeyWord key={idx} keyWord={keyWord} book={book}
                                            usageButton={usageButton}/>;}
        );
        if (keyWords.length === 0) {
            return <div/>
        }
        return <div className="horizontal-scroll">
            <h3>Key Words</h3>
            <table className="table table-bordered">
                <thead>
                    <tr className="dFlex">
                        <th style={{width: '15%', padding: '5px'}}>{"Strong's"}</th>
                        <th style={{width: '15%', padding: '5px'}}>{"Count"}</th>
                        <th style={{width: '70%', padding: '5px'}}>Usages</th>
                    </tr>
                </thead>
                <tbody>{keyWords}</tbody>
            </table>
        </div>;
    }
}


class Chapter extends React.Component {
    constructor(){
        super();
        this.state = {};
    }
    async getData(){
        const verseData = await axios.get('/api/verses/?book=' + this.props.book + '&chapter=' + this.props.chapter);
        this.setState({verses: _.sortBy(verseData.data.results, 'verse')});
    }
    componentDidMount(){
       this.getData();
    }
    render() {
        if (!this.state.verses) {return <div/>};
        var showStrongs = this.props.showStrongs;
        var verses = _.map(this.state.verses, function(item, idx) {
            return <Verse key={item.verse} verse={item} showStrongs={showStrongs}/>;
        })

        var nextChap = Number(this.props.chapter) + 1;
        var nextBook = Number(this.props.book);
        if (lastChapters && nextChap > Number(lastChapters[this.props.book])) {
            nextChap = 0;
            nextBook += 1
        }

        var prevBook = Number(this.props.book);
        var prevChap = Number(this.props.chapter) - 1;
        if (lastChapters && prevChap < 0) {
            prevBook -= 1
            prevChap = lastChapters[prevBook];
        }

        return <div>
            <hr/>
            <Title book={this.props.book} chapter={this.props.chapter}/>
            <hr/>
            {verses}
            <KeyWordList book={this.props.book} chapter={this.props.chapter}/>
            <hr/>
            <div className="nav nav-fill justify-content-center">
                <NavButton book={prevBook} chapter={prevChap} direction='Previous'/>
                <NavButton book={nextBook} chapter={nextChap} direction='Next'/>
            </div>
        </div>;
    };
  }


class Introduction extends React.Component {
    render() {
        var nextChap = 1;
        var nextBook = Number(this.props.book);
        var prevBook = Number(this.props.book) - 1;
        var prevChap = lastChapters[prevBook];

        return <div>
            <hr/>
            <Title book={this.props.book} chapter={'Introduction'}/>
            <hr/>
            <KeyWordList book={this.props.book}/>
            <hr/>
            <div className="nav nav-fill justify-content-center">
                <NavButton book={prevBook} chapter={prevChap} direction='Previous'/>
                <NavButton book={nextBook} chapter={nextChap} direction='Next'/>
            </div>
        </div>
    };
  }


export {Chapter, Introduction};
