export const ADD_WORD = 'ADD_WORD';
export const CLEAR_WORDS = 'CLEAR_WORDS';
export const HIDE_STRONGS = 'HIDE_STRONGS';
export const REMOVE_WORD = 'REMOVE_WORD';
export const SHOW_STRONGS = 'SHOW_STRONGS';
export const TOGGLE_MULTI_SELECT = 'TOGGLE_MULTI_SELECT';
export const TOGGLE_INTERLINEAR = 'TOGGLE_INTERLINEAR';

export function addWord(payload) {
    return { type: ADD_WORD, payload}
};

export function clearWords(payload) {
    return { type: CLEAR_WORDS, payload}
};

export function hideStrongs(payload) {
    return { type: HIDE_STRONGS, payload }
};

export function removeWord(payload) {
    return { type: REMOVE_WORD, payload}
};

export function showStrongs(payload) {
    return { type: SHOW_STRONGS, payload }
};

export function toggleMultiSelect(payload) {
    return { type: TOGGLE_MULTI_SELECT, payload}
};

export function toggleInterlinear(payload) {
    return { type: TOGGLE_INTERLINEAR, payload}
}