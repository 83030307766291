import React from 'react';

function FAQ () {
    return (<div>
        <h3>FAQ</h3>
        <h5>Why make Concordy?</h5>
        <p>
            I made Concordy for myself. I wanted a minimalist, all-in-one Bible study tool that was free, used a critical
            text, and didn't require any downloads. I'm happy to share it with others.
        </p>
        <h5>How does it work?</h5>
        <div>
            Concordy has three basic features:
            <ul>
                <li>Click on an English word to get its definition and
                    morphology <span>(<a href="https://en.wikipedia.org/wiki/Declension">declensions</a> </span>
                    and <a href="https://en.wikipedia.org/wiki/Grammatical_conjugation">conjugations</a>).
                </li>
                <li>
                    Search a single word, and find all other uses of that word, either by that same author or in the entire
                    Bible.
                </li>
                <li>
                    Search a phrase and find all other uses of that phrase in the entire Bible.
                </li>
            </ul>
        </div>
        <p>It's pretty straightforward. Just click around for a bit and you'll figure it out.</p>
        <h5>Why are some words in blue or green?</h5>
        <p>
            Words in blue are in the <a className="imperative" href="https://en.wikipedia.org/wiki/Imperative_mood">imperative</a> or <a className="imperative" href="https://en.wikipedia.org/wiki/Jussive_mood">jussive</a> moods, words
            in green are in the <a className="subjunctive" href="https://en.wikipedia.org/wiki/Subjunctive_mood">subjunctive mood</a>, and
            words in red are <a className="connector" href="https://ancientgreek.pressbooks.com/chapter/10/">logical conjunctions</a>. I like to see
            them in a different color to aid with observation
            during <a href="https://www.gotquestions.org/inductive-Bible-study.html">inductive Bible study</a>.
        </p>
        <h5>Who maintains Concordy?</h5>
        <p>
            Concordy is maintained by me, in Austin, Texas.
        </p>
        <h5>Where does Concordy's data come from?</h5>
        <p>
            Concordy's translation (and underlying parsings) comes from the hard work of the translation team
            at <a href="https://greekbible.org/">Berean Bible</a>. The digital form of the Abbott-Smith lexicon was produced
            by the hard work of
            a <a href="https://github.com/translatable-exegetical-tools/Abbott-Smith/">team of contributors</a> who digitized
            it and placed it in the public domain. Commentaries come from the hard work of the team at <a href="https://www.ccel.org/">CCEL</a>.
        </p>
        <h5>Can I support Concordy financially?</h5>
        <p>
            Nope! Concordy is cheap. So share it with a friend.
        </p>
    </div>);
}

export default FAQ;