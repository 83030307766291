import React from 'react';
import _ from 'lodash';
import { connect } from "react-redux";


import {hideStrongs, showStrongs} from '../../js/actions'
import store from "../../js/store/index";
import {toggleInterlinear, toggleMultiSelect} from '../../js/actions'
import {bookStyles, lastChapters, newTestamentBooks, oldTestamentBooks} from '../common/constants'


class MultiSelectSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick () {
        window.open('/usages/?selectedWords=' + Array.from(store.getState().selectedWords).join(','), '_self');
    }
    render () {
        return <a href='javascript:;' className="nav-item quick-nav-item" onClick={this.handleClick}>Search</a>;
    }
}


class ConnectedMultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick () {
        store.dispatch(toggleMultiSelect(!store.getState().multiSelect));
    }
    render () {
        return <span>
            <a href='javascript:;' className="nav-item quick-nav-item" onClick={this.handleClick}>
                {store.getState().multiSelect ? 'Normal View' : 'Search Multiple Words'}
            </a>
            {store.getState().multiSelect ? <MultiSelectSearch/> : null}
        </span>
    }
}


const MultiSelect = connect(state => {
    return { isClicked: state.multiSelect };
})(ConnectedMultiSelect);


class ConnectedInterlinear extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick () {
        store.dispatch(toggleInterlinear(!store.getState().interlinear));
    }
    render () {
        return <a href='javascript:;' className="nav-item quick-nav-item" onClick={this.handleClick}>
                {store.getState().interlinear ? 'Regular View' : 'Original Language'}
            </a>
    }
}


const Interlinear = connect(state => {
    return { isClicked: state.interlinear };
})(ConnectedInterlinear);


class QuickNavChapter extends React.Component {
    constructor (props) {
        super(props);
        this.state = {isClicked: false};
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.setState(state => ({
          isClicked: !state.isClicked
        }));
    }
    render (){
        if (this.state.isClicked) {
            return <BookList/>
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        var chapters = _.range(1, lastChapters[this.props.bookNo] + 1);
        var navLink = '/?book=' + this.props.bookNo;
        chapters = _.map(chapters, function(number) {
            return <a key={number} href={navLink + '&chapter=' + number}
                    className="quick-nav chapter nav-item">{number}</a>
        });
        return <div>
            <a href="javascript:;" className="quick-nav back" onClick={this.handleClick}>&larr;</a>
            <a href={navLink} className="quick-nav chapter nav-item">Int</a>
            {chapters}
        </div>;
    }
}

class QuickNavBook extends React.Component {
    constructor (props) {
        super(props);
        this.state = {isClicked: false};
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.setState(state => ({
          isClicked: !state.isClicked
        }));
        this.props.collapse(this.props.bookNo);
    }
    render() {
        if (this.state.isClicked) {
            return <div>
                <QuickNavChapter bookNo={this.props.bookNo} />
            </div>;
        }
        var style = {};
        if (this.props.bookName === 'Philemon') {
            var abbr = 'Phm';
        } else if (this.props.bookName === 'Judges') {
            var abbr = 'Jdg';
        } else {
            var abbr = this.props.bookName.replace(' ', '').slice(0, 3);
        }
        style['backgroundColor'] = bookStyles[abbr];
        return <a style={style} href="javascript:;" className="quick-nav book"  onClick={this.handleClick}>{abbr}</a>;
    }
}

class BookList extends React.Component {
    constructor () {
        super();
        this.state = {isClicked: false, selectedBook: null};
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick (bookNo) {
        this.setState(state => ({
          isClicked: !state.isClicked,
          selectedBook: bookNo,
        }));
    }
    render () {
        var handleClick = this.handleClick;
        if (this.state.selectedBook) {
            return <QuickNavChapter bookNo={this.state.selectedBook} />;
        }
        var otButtons = _.map(oldTestamentBooks, function (book, idx) {
            return <QuickNavBook key={idx} bookNo={idx} bookName={book} collapse={handleClick}/>;
        });
        var ntButtons = _.map(newTestamentBooks, function (book, idx) {
            return <QuickNavBook key={idx} bookNo={idx} bookName={book} collapse={handleClick}/>;
        });
        return (
            <div className="nav nav-fill justify-content-center">
                <div>
                    {otButtons}
                    <br/>
                    {ntButtons}
                </div>
            </div>
        );
    }
}

class QuickNav extends React.Component {
    constructor (props) {
        super(props);
        this.state = {};
        this.handleClick = this.handleClick.bind(this);
        this.toggleStrongs = this.toggleStrongs.bind(this);
    }
    handleClick () {
        this.setState(state => ({
          isClicked: !state.isClicked,
        }));
    }
    toggleStrongs () {
        if (store.getState().showStrongs) {
            store.dispatch(hideStrongs());
            this.setState(state => ({
              showStrongs: false,
            }));
        } else {
            store.dispatch(showStrongs());
            this.setState(state => ({
              showStrongs: true,
            }));
        }
    }
    render() {
        var strongsButton = <a href="javascript:;" className="nav-item quick-nav-item" onClick={this.toggleStrongs}>
            {this.state.showStrongs ? "Hide Strong's" : "Show Strong's"}
        </a>;
        if (!this.state.isClicked) {
            return <div className="quick-nav">
                <a href="javascript:;" className="nav-item quick-nav-item" onClick={this.handleClick}>
                    Select Book
                </a>
                {this.props.chapterView ? strongsButton : null}
                {this.props.chapterView ? <Interlinear/> : null}
                {this.props.chapterView ? <MultiSelect/> : null}
            </div>
        }
        return <div className="quick-nav">
            <div>
                <a href="javascript:;" className="nav-item quick-nav-item back" onClick={this.handleClick}>
                    &larr; Hide Books
                </a>
                {this.props.chapterView ? strongsButton : null}
                {this.props.chapterView ? <Interlinear/> : null}
                {this.props.chapterView ? <MultiSelect/> : null}
            </div>
            <BookList/>
        </div>
    }
}

export default QuickNav