import _ from 'lodash';
import {
    ADD_WORD,
    CLEAR_WORDS,
    HIDE_STRONGS,
    REMOVE_WORD,
    SHOW_STRONGS,
    TOGGLE_MULTI_SELECT,
    TOGGLE_INTERLINEAR
} from "../actions";


const initialState = {
  showStrongs: false,
  selectedWords: new Set(),
  multiSelect: false,
  interlinear: false,
};

function rootReducer(state = initialState, action) {
  if (action.type === HIDE_STRONGS) {
    return Object.assign({}, state, {
      showStrongs: false,
    });
  } else if (action.type === SHOW_STRONGS) {
    return Object.assign({}, state, {
      showStrongs: true,
    });
  } else if (action.type === ADD_WORD) {
    return Object.assign({}, state, {
        selectedWords: state.selectedWords.add(action.payload),
    })
  } else if (action.type === REMOVE_WORD) {
    var selectedWords = _.clone(state.selectedWords);
    selectedWords.delete(action.payload);
    return Object.assign({}, state, {
        selectedWords: selectedWords,
    })
  } else if (action.type === CLEAR_WORDS) {
    return Object.assign({}, state, {
        selectedWords: new Set(),
    })
  } else if (action.type === TOGGLE_MULTI_SELECT) {
    return Object.assign({}, state, {
        multiSelect: action.payload,
    })
  } else if (action.type === TOGGLE_INTERLINEAR) {
    return Object.assign({}, state, {
        interlinear: action.payload,
    })
  }
  return state;

};
export default rootReducer;
