// @flow

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import About from "./components/staticPages/about"
import FAQ from "./components/staticPages/faq"
import {Container, ChapterContainer} from "./components/common/navContainer"
import {Definition, MultiUsage, Usage} from "./components/usage/usage"
import {TextAnalytics} from './components/analytics/analytics'

function VerseRouter({ location }) {
    let params = new URLSearchParams(location.search);
    var book = params.get("book")
    var chapter = params.get("chapter")
    if (!book && !chapter) {
        book = 0;
        chapter = 1;
    }
    return <ChapterContainer book={book || '0'} chapter={chapter}/>
}

function UsageRouter({ match }) {
    return <Container component={<Usage word={match.params.id} />}/>;
}

function MultiUsageRouter({ location }) {
    let params = new URLSearchParams(location.search);
    return <Container component={<MultiUsage selectedWords={params.get('selectedWords')} />}/>;
}

function AnalyticsRouter({ match}) {
    return <Container component={<TextAnalytics/>}/>;
}

function DefinitionRouter({ match }) {
    return <Container component={<Definition strongs={match.params.id} />}/>;
}

function AboutRouter() {
    return <Container component={<About/>}/>;
}

function FAQRouter() {
    return <Container component={<FAQ/>}/>;
}

function MasterRouter() {
    return (
        <Router>
            <Route exact path="/" component={VerseRouter} />
            <Route path="/about" component={AboutRouter} />
            <Route path="/faq" component={FAQRouter} />
            <Route path="/definition/:id" component={DefinitionRouter} />
            <Route path="/words/:id" component={UsageRouter} />
            <Route path="/usages/" component={MultiUsageRouter} />
            <Route path="/analytics/" component={AnalyticsRouter} />
        </Router>
    );
}

export default MasterRouter;
