import _ from 'lodash';
import React from 'react';
import axios from 'axios';
import ReactTooltip from 'react-tooltip'
import {SimpleModal} from '../common/modals'


class DisplaySentences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(event) {
    this.setState({isHidden: !this.state.isHidden});
  }
  render() {
    if (this.props.listData.length < 1) { return <></>} else if (this.state.isHidden) {
        return <div><a href="javascript:;" className="nav-item quick-nav-item" onClick={this.handleClick}>Show Hard Sentences</a></div>;
    }
    var data = _.map(this.props.listData, function (datum) {
        return <li className="tightList">{datum[0]} <strong>(Grade: {datum[1]})</strong></li>;
    });
    return (<>
        <a href="javascript:;" className="nav-item quick-nav-item" onClick={this.handleClick}>Hide Hard Sentences</a>
        <div><strong><label>Potentially difficult sentences:</label></strong><div><ul>{data}</ul></div></div>
    </>);
  }
}

class DisplayWords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(event) {
    this.setState({isHidden: !this.state.isHidden});
  }
  render() {
    if (this.props.listData.length < 1) { return <></>} else if (this.state.isHidden) {
        return <div><a href="javascript:;" className="nav-item quick-nav-item" onClick={this.handleClick}>Show Hard Words</a></div>;
    }
    var data = _.map(this.props.listData, function (datum) {
        return <li className="tightList">{datum[0]} <strong>(Grade: {datum[1]})</strong></li>;
    });
    var data = _.map(this.props.listData, function (datum) {
        return <li className="tightList">{datum}</li>;
    });
    return (<>
        <a href="javascript:;" className="nav-item quick-nav-item" onClick={this.handleClick}>Hide Hard Words</a>
        <div><strong><label>Potentially difficult words:</label></strong></div><ul>{data}</ul>
    </>);
  }
}

function DisplayScore(props) {
    return (<>
    <div>
        <strong><label>{props.label}</label></strong>
        <sup>
            <SimpleModal
                content={props.tooltip}
                contentLabel={'Explanation'}
                iconName={'fas fa-info-circle'}
                width="50%"
            />
        </sup>&nbsp;&nbsp;{props.score}
    </div>
    </>);
}

function TextAnalyticsDisplay(props) {
    var gCTooltip = 'Approximate measure of "gospel-centrality" based on keyword frequency. This ';
    gCTooltip += 'measure is a percentile based on the DesiringGod sermon corpus (i.e., a score of ';
    gCTooltip += '60 suggests the sermon is more "gospel-centered" than 60% of John Piper\'s sermons. ';
    gCTooltip += 'This is an imprecise measure.)';

    var scriptureTooltip = 'Approximate measure of "textuality" based on keyword frequency. This measure is a '
    scriptureTooltip += 'percentile based on the DesiringGod sermon corpus (i.e., a score of 60 suggests the sermon '
    scriptureTooltip += 'is more "textual" than 60% of John Piper\'s sermons. This is an imprecise measure.)'
    return (<>
        <DisplayScore
            label={'Approx. Grade Level'}
            score={props.grade_level}
            tooltip={'Approximate grade level of the sermon, based on a mix of readability tests'}
        />
        <DisplayScore
            label={'Gospel-Centrality Score'}
            score={props.gospel_score}
            tooltip={gCTooltip}
        />
        <DisplayScore
            label={'Scripture Score'}
            score={props.sermon_scripture_correspondence || 'N/A'}
            tooltip={scriptureTooltip}
        />
        <DisplaySentences listData={props.hard_sentences}/>
        <DisplayWords listData={props.hard_words}/>
    </>)
}

class TextAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptureText: '',
      sermonText: '',
      isLoading: false,
    };

    this.handleScriptureChange = this.handleScriptureChange.bind(this);
    this.handleSermonChange = this.handleSermonChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getData(){
      this.setState(state => ({
        isLoading: true,
      }));
      var url = '/api/analyze_text/';
      var formData = new FormData();
      formData.set('scriptureText', this.state.scriptureText);
      formData.set('sermonText', this.state.sermonText);
      var textAnalytics = await axios.post(url, formData);
      this.setState(state => ({
        textAnalytics: textAnalytics.data,
        isLoading: false,
      }));
  }

  handleScriptureChange(event) {
    this.setState({scriptureText: event.target.value});
  }

  handleSermonChange(event) {
    this.setState({sermonText: event.target.value});
  }

  handleSubmit(event) {
    this.getData()
    event.preventDefault();
  }

  render() {
    if (this.state.textAnalytics) {
        var display = (<TextAnalyticsDisplay
                       grade_level={this.state.textAnalytics.grade}
                       gospel_score={this.state.textAnalytics.gospel_score}
                       hard_sentences={this.state.textAnalytics.hard_sentences}
                       hard_words={this.state.textAnalytics.hard_words}
                       sermon_scripture_correspondence={this.state.textAnalytics.sermon_scripture_correspondence}/>);
    } else if (this.state.isLoading) {
        var display = <div>Loading...</div>
    } else {
        var display = <><p>(Sermon texts are not stored)</p></>
    }
    return (
        <>
            <form class="form">
                <div className="formGroup">
                    <h5>Sermon Text:</h5>
                        <textarea
                            value={this.state.sermonText}
                            placeholder="Copy your sermon text here"
                            onChange={this.handleSermonChange}
                        />
                    </div>
                    <h5>Scripture Text:</h5>
                    <textarea
                        value={this.state.scriptureText}
                        placeholder="Copy your scripture text here (optional)"
                        onChange={this.handleScriptureChange}
                    />
            </form>
                    <div>
                        <a href="javascript:;" className='quick-nav-item' onClick={this.handleSubmit}>Submit</a>
                    </div>
            {display}
        </>
    );
  }
}





export {TextAnalytics};